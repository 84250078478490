import React from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import { Nav } from '../../components';
import { WorkSectionHeader } from '../../components';
import GlobalLayout from '../../layouts/GlobalLayout';

const Bonfire = () => {
  const imgStyleMobile = {
    maxWidth: '320px',
    placeSelf: 'center',
  };
  return (
    <GlobalLayout>
      <Nav />
      <WorkSectionHeader
        workType='Concept'
        workName='Bonfire'
        what='Crowdsourced music curation. Create a group, submit songs to the group, members upvote or downvote. ✌️social music✌️'
        who='Side Project'
        role='Product Design'
      />

      <ShowcaseContainerMobile>
        <StaticImage
          alt='lolololololololololololololololol'
          style={imgStyleMobile}
          src='../../images/work/bonfire/splash.png'
        />
        <StaticImage
          alt='lolololololololololololololololol'
          style={imgStyleMobile}
          src='../../images/work/bonfire/home.png'
        />
        <StaticImage
          alt='lolololololololololololololololol'
          style={imgStyleMobile}
          src='../../images/work/bonfire/groupDetail.png'
        />
        <StaticImage
          alt='lolololololololololololololololol'
          style={imgStyleMobile}
          src='../../images/work/bonfire/songPlaying.png'
        />
      </ShowcaseContainerMobile>
    </GlobalLayout>
  );
};

const ShowcaseContainerMobile = styled.section`
  display: flex;
  justify-content: center;
  padding: 3.33em 0;
  max-width: 100vw;
  gap: 2.22em;
  flex-flow: row wrap;
`;

export default Bonfire;
